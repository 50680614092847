.modal-open {
  overflow: hidden;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(4px);
  z-index: 1040;
}

.modern-modal {
  position: fixed !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1050;
}

.modern-modal .modal-dialog {
  margin: 0 auto;
  max-width: 500px;
  width: 90%;
  position: relative;
  pointer-events: auto;
}

.modern-modal .modal-content {
  border-radius: 12px;
  border: 1px solid rgba(107, 114, 128, 0.3);
  background: linear-gradient(to bottom right, #1f2937, #111827);
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.2), 0 10px 10px -5px rgba(0, 0, 0, 0.1);
  position: relative;
  width: 100%;
  color: white;
  overflow: hidden;
  pointer-events: auto;
}

.modern-modal .modal-header {
  border-bottom: 1px solid rgba(107, 114, 128, 0.3);
  padding: 1.5rem;
  background: rgba(255, 255, 255, 0.05);
}

.modern-modal .modal-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #60a5fa;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}

.modern-modal .modal-body {
  padding: 1.5rem;
  position: relative;
}

.modern-modal .modal-body::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(45deg, rgba(59, 130, 246, 0.1), rgba(147, 51, 234, 0.1));
  pointer-events: none;
}

.modern-modal .btn {
  border-radius: 8px;
  font-weight: 500;
  transition: all 0.2s ease;
  margin-bottom: 0.75rem;
  padding: 0.75rem 1rem;
  width: 100%;
  text-align: center;
  position: relative;
  overflow: hidden;
}

.modern-modal .btn:last-child {
  margin-bottom: 0;
}

.modern-modal .btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
}

.modern-modal .btn-outline-primary {
  background: rgba(59, 130, 246, 0.1);
  border: 2px solid #3b82f6;
  color: #60a5fa;
}

.modern-modal .btn-primary {
  background: linear-gradient(to right, #3b82f6, #2563eb);
  border: none;
  color: white;
}

.modern-modal .btn-info {
  background: linear-gradient(to right, #0dcaf0, #0891b2);
  border: none;
  color: white;
}

.modern-modal .btn-success {
  background: linear-gradient(to right, #10b981, #059669);
  border: none;
  color: white;
}

.modern-modal .close {
  position: absolute;
  right: 1rem;
  top: 1rem;
  font-size: 1.5rem;
  color: #60a5fa;
  opacity: 0.7;
  transition: all 0.2s ease;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.5rem;
  z-index: 1;
}

.modern-modal .close:hover {
  opacity: 1;
  transform: rotate(90deg);
}

.modern-modal .ant-select {
  width: 100%;
  margin-bottom: 1rem;
}

.modern-modal .ant-select-selector {
  border-radius: 8px !important;
  height: 42px !important;
  padding: 5px 12px !important;
  background: rgba(255, 255, 255, 0.1) !important;
  border: 1px solid rgba(107, 114, 128, 0.3) !important;
  color: white !important;
}

.modern-modal .ant-select-selection-placeholder,
.modern-modal .ant-select-selection-item {
  line-height: 32px !important;
  color: rgba(255, 255, 255, 0.8) !important;
}

.modern-modal p {
  color: #93c5fd;
  margin-bottom: 1rem;
}

/* Fix for Ant Design Select Dropdown */
.ant-select-dropdown {
  background: #1f2937 !important;
  border: 1px solid rgba(107, 114, 128, 0.3) !important;
  border-radius: 8px !important;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.3) !important;
}

.ant-select-item {
  color: white !important;
  padding: 8px 12px !important;
}

.ant-select-item-option-selected {
  background: rgba(59, 130, 246, 0.2) !important;
}

.ant-select-item-option-active {
  background: rgba(59, 130, 246, 0.1) !important;
} 