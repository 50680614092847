/* Custom styles for react-big-calendar */
.rbc-calendar {
  background-color: #1f2937;
  border-radius: 0.75rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif;
  border: 1px solid #374151;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.rbc-header {
  padding: 12px;
  font-weight: 600;
  color: #e5e7eb;
  font-size: 1rem;
  background-color: #374151;
  border-bottom: 2px solid #4b5563;
}

.rbc-event {
  padding: 0 !important;
  border: none !important;
  background: linear-gradient(135deg, #8B5CF6, #7C3AED) !important;
  margin: 1px;
  border-radius: 0.5rem;
  overflow: hidden;
  border: 2px solid transparent !important;
  transition: all 0.2s ease;
}

.rbc-event:hover {
  transform: translateY(-1px);
  border-color: #A78BFA !important;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
}

.rbc-event.rbc-selected {
  background: linear-gradient(135deg, #6D28D9, #5B21B6) !important;
  border-color: #F9FAFB !important;
  box-shadow: 0 0 0 2px #F9FAFB !important;
}

.rbc-event-content {
  font-size: 0.875rem !important;
  font-weight: 500 !important;
  color: white !important;
  height: 100% !important;
  padding: 8px !important;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}

.rbc-today {
  background-color: #374151 !important;
}

.rbc-toolbar {
  margin-bottom: 20px;
  padding: 8px;
}

.rbc-toolbar button {
  color: #e5e7eb;
  background-color: #4b5563;
  border: 1px solid #6b7280;
  padding: 8px 16px;
  border-radius: 0.5rem;
  font-size: 0.875rem;
  font-weight: 500;
  transition: all 0.2s;
}

.rbc-toolbar button:hover {
  background-color: #6b7280;
  transform: translateY(-1px);
}

.rbc-toolbar button.rbc-active {
  background: linear-gradient(to right, #3b82f6, #2563eb);
  color: white;
  border: none;
}

.rbc-time-slot {
  color: #9ca3af;
  font-size: 0.875rem;
}

.rbc-timeslot-group {
  min-height: 80px;
  border-bottom: 1px solid #4b5563;
}

.rbc-time-view {
  border: 1px solid #4b5563;
  border-radius: 0.5rem;
  background-color: #1f2937;
}

.rbc-time-header {
  background-color: #374151;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.rbc-time-content {
  background-color: #1f2937;
}

.rbc-current-time-indicator {
  background-color: #ef4444;
  height: 2px;
}

.calendar-event {
  margin: 2px !important;
  transition: all 0.2s ease;
  color: white !important;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2) !important;
  border-radius: 0.5rem !important;
  background: linear-gradient(135deg, #8B5CF6, #7C3AED) !important;
  border: 2px solid transparent !important;
}

.calendar-event:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 6px rgba(0,0,0,0.3) !important;
  opacity: 1 !important;
  border-color: #A78BFA !important;
}

.calendar-event.selected-event {
  border-color: #F9FAFB !important;
  box-shadow: 0 0 0 2px #F9FAFB !important;
  background: linear-gradient(135deg, #6D28D9, #5B21B6) !important;
}

.rbc-event {
  border: none !important;
  opacity: 0.95 !important;
}

.rbc-event-content {
  font-size: 0.875rem !important;
  font-weight: 500 !important;
  color: white !important;
  height: 100% !important;
  padding: 4px 8px !important;
}

.rbc-day-slot .rbc-event {
  border: none;
  padding: 0;
}

.rbc-row-segment {
  padding: 2px 4px;
}

.rbc-off-range-bg {
  background-color: #111827;
}

.rbc-off-range {
  color: #6b7280;
}

.rbc-date-cell {
  color: #e5e7eb;
  padding: 4px;
}

.rbc-month-view {
  border-radius: 0.5rem;
  overflow: hidden;
}

.rbc-month-row {
  border-color: #4b5563;
}

.rbc-day-bg {
  border-color: #4b5563;
}

/* Route Indicators */
.route-event {
  background: linear-gradient(135deg, #8B5CF6, #7C3AED) !important;
  border: 2px solid transparent !important;
}

.route-event:hover {
  border-color: #A78BFA !important;
}

.route-event.selected-event {
  border-color: #F9FAFB !important;
  box-shadow: 0 0 0 2px #F9FAFB !important;
  background: linear-gradient(135deg, #6D28D9, #5B21B6) !important;
}

.recurring-event {
  background: linear-gradient(135deg, #9333EA, #7C3AED) !important;
}

.recurring-event:hover {
  border-color: #C4B5FD !important;
}

.recurring-event.selected-event {
  border-color: #F9FAFB !important;
  box-shadow: 0 0 0 2px #F9FAFB !important;
  background: linear-gradient(135deg, #7E22CE, #6D28D9) !important;
}

/* Route job styling */
.route-job {
  background: linear-gradient(135deg, #8B5CF6, #7C3AED) !important;
}

.route-job:hover {
  border-color: #A78BFA !important;
}

.route-job.selected-event {
  border-color: #F9FAFB !important;
  box-shadow: 0 0 0 2px #F9FAFB !important;
  background: linear-gradient(135deg, #6D28D9, #5B21B6) !important;
}

/* Job pool styles */
.job-pool-item {
  background: linear-gradient(135deg, #1f2937, #111827);
  border: 1px solid #374151;
  border-radius: 0.5rem;
  padding: 1rem;
  margin-bottom: 0.5rem;
  transition: all 0.2s;
}

.job-pool-item:hover {
  transform: translateY(-2px);
  border-color: #3b82f6;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
}

/* Loading state */
.calendar-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 1.25rem;
  color: #e5e7eb;
  background-color: #1f2937;
}

/* Time gutter */
.rbc-time-gutter {
  background-color: #374151;
}

.rbc-time-gutter .rbc-timeslot-group {
  border-color: #4b5563;
}

/* Day columns */
.rbc-day-slot {
  background-color: #1f2937;
}

.rbc-time-column {
  border-color: #4b5563;
}

/* Event overlapping */
.rbc-event.rbc-selected {
  background: linear-gradient(to right, #4f46e5, #4338ca) !important;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1) !important;
}

/* Drag and drop styles */
.rbc-addons-dnd .rbc-row-content {
  pointer-events: none;
}

.rbc-addons-dnd .rbc-event {
  pointer-events: all;
}

.rbc-addons-dnd .rbc-row-segment {
  pointer-events: all;
}

/* Time indicator */
.rbc-current-time-indicator {
  background-color: #ef4444;
  height: 2px;
}

/* Event Styles */
.event-wrapper {
  height: 100%;
  width: 100%;
}

.event-wrapper.month-view {
  min-height: 24px;
}

.event-wrapper.week-view {
  min-height: 60px;
}

.event-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 4px;
}

.event-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2px;
}

.event-time {
  font-size: 0.75rem;
  opacity: 0.9;
}

.recurring-badge {
  font-size: 1rem;
  margin-left: 4px;
}

.event-title {
  font-weight: 600;
  font-size: 0.875rem;
  margin-bottom: 2px;
}

.event-customer {
  font-size: 0.75rem;
  opacity: 0.9;
}

/* View-specific styles */
.rbc-month-view .event-content {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.rbc-time-view .event-content {
  padding: 8px;
}

/* Route Indicators */
.route-event {
  border-left: 4px solid #1d4ed8 !important;
}

.recurring-event {
  border-right: 4px solid #15803d !important;
}

/* Success Animation */
@keyframes fade-in-up {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-fade-in-up {
  animation: fade-in-up 0.3s ease-out;
}

/* Add a distinct style for events in the same route */
.same-route-event {
  border-left: 4px solid #1d4ed8;
}

/* Add a small route badge */
.route-badge {
  position: absolute;
  left: -2px;
  top: 50%;
  transform: translateY(-50%);
  width: 4px;
  height: 80%;
  background-color: #1d4ed8;
  border-radius: 2px;
}

/* Route event styling */
.route-event {
  background-color: #2563eb !important;
  border: 2px solid #1d4ed8 !important;
  border-radius: 4px !important;
  margin: 2px !important;
  cursor: pointer !important;
}

.route-event .rbc-event-content {
  font-weight: bold !important;
  padding: 4px !important;
  text-align: center !important;
}

/* Add these at the end of your existing CSS file */
.event-wrapper.week-view {
  min-height: 60px;
}

.event-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 4px;
}

.event-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2px;
}

/* Agenda View Styles */
.rbc-agenda-view {
  background-color: #1f2937;
  color: #f3f4f6;
}

.rbc-agenda-table {
  border-color: #4b5563;
}

.rbc-agenda-table thead {
  background: linear-gradient(to bottom, #374151, #1f2937);
}

.rbc-agenda-table th {
  padding: 12px;
  font-weight: 600;
  color: #f9fafb;
  font-size: 1rem;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  border-bottom: 2px solid #4b5563;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}

.rbc-agenda-table td {
  padding: 12px;
  border-color: #4b5563;
  font-weight: 500;
  color: #e5e7eb;
}

.rbc-agenda-time-cell {
  font-weight: 600 !important;
  color: #f3f4f6 !important;
  background-color: #2d3748;
}

.rbc-agenda-event-cell {
  font-weight: 500;
  background: linear-gradient(to right, #2d3748, #1f2937);
  color: #f3f4f6;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.rbc-agenda-date-cell {
  font-weight: 600 !important;
  color: #f3f4f6 !important;
  background-color: #2d3748;
}

.rbc-agenda-table tr:hover td {
  background-color: #374151;
}

.rbc-agenda-view .rbc-header {
  border-bottom: 2px solid #4b5563;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  color: #f9fafb;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}

/* Date range header in agenda view */
.rbc-toolbar-label {
  font-size: 1.25rem;
  font-weight: 600;
  color: #f9fafb;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  padding: 8px 0;
  background: linear-gradient(to right, #374151, #1f2937);
  border-radius: 0.5rem;
  margin: 8px 0;
}